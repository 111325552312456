export const highlightQuery = filters =>
  filters
    .filter(filter => filter.highlightKeyword && filter.value)
    .map(({ value, highlightKeyword }) => [`${highlightKeyword}=${value}`])
    .join('&');

export const highlightKeywords = filters =>
  filters
    .filter(
      filter => (filter.highlightKeyword || filter.highlight) && filter.value
    )
    .flatMap(filter => {
      if (!filter.possibleQuotedHighlight) {
        return [filter.value];
      }
      const isQuoted =
        filter.value.startsWith('"') && filter.value.endsWith('"');
      const value = filter.value.replace(/"/g, '');
      if (isQuoted) {
        return [value];
      }
      return value.split(' ');
    });
