export const getAdvancedQuery = filters => {
  const splitKeys = [];
  const query = filters
    .flatMap(({ value, name, options, keys, splitRequests }) => {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return [];
        }
        const mappedOptions = value.map(v => options.find(o => o.label === v));
        return mappedOptions.map(o => `${name}=${o.value}`);
      }
      if (value) {
        if (keys) {
          if (splitRequests) {
            splitKeys.push({
              keys,
              value,
            });
            return [];
          }
          return keys.map(key => `${key}=${value}`);
        }
        return [`${name}=${value}`];
      }
      return [];
    })
    .join('&');

  return {
    query,
    splitKeys,
  };
};
