<template>
  <div class="search">
    <template v-if="!sublist">
      <search-bar
        v-if="!advanced"
        :relation="$route.name.replace('search-', '')"
        :active="true"
        class="search__bar mb-4 desktop"
      />

      <h1 class="heading heading--medium-small color-primary mb-4 mobile">
        Wyniki wyszukiwania
      </h1>
      <h1 class="search__title heading color-primary mb-16 tablet-desktop">
        wyszukiwanie
        <span class="heading__sub">
          {{ advanced ? 'zaawansowane' : combo && `„${combo}”` }}
          <base-spinner v-if="loadingInBackground" gray />
        </span>
      </h1>
    </template>

    <div
      class="search__main item-list mb-48"
      :class="{ 'search__main--advanced': advanced }"
    >
      <div
        v-if="advanced && advancedSearchOpen"
        class="search__advanced"
        :class="{ 'search__advanced--collapsed': advancedCollapse }"
      >
        <div class="search__advanced-inner">
          <button class="search__advanced-open" @click="$emit('advanced-open')">
            <span class="visuallyhidden">Otwórz filtry</span>
          </button>

          <form @submit.prevent="search" class="search__form">
            <div class="search__form-fields">
              <h3
                class="search__form-title heading color-primary mb-12 mobile-tablet"
              >
                wyszukiwanie<br />
                zaawansowane
              </h3>
              <p class="search__form-info mb-12 desktop">
                Wpisz wyszukiwane frazy
              </p>
              <div v-for="filter in filters" :key="filter.name" class="mb-8">
                <base-input
                  v-if="filter.type === 'text'"
                  v-model="filter.value"
                  :name="filter.name"
                  :id="`advanced-search-input-${filter.name}`"
                  type="search"
                >
                  {{ filter.label }}
                </base-input>
                <base-multiselect
                  v-else-if="filter.type === 'select'"
                  v-model="filter.value"
                  :name="filter.name"
                  :options="filter.options.map(o => o.label)"
                  :disabled="filter.optionsLoading"
                  :search="true"
                >
                  {{ filter.label }}
                </base-multiselect>
              </div>
            </div>
            <base-button class="search__form-submit" type="submit" full>
              <span class="visuallyhidden">Szukaj</span>
              <base-icon-search />
            </base-button>
          </form>
        </div>
      </div>

      <div v-if="itemsVisible || emptySearch" class="search__results">
        <p v-if="invalidQuery">
          Wpisane kryterium odwołuje się do wszystkich projektów. Spróbuj
          poszukać wybranych spośród nich
        </p>
        <p v-else-if="emptySearch">
          Wpisz frazę
        </p>
        <base-spinner
          v-else-if="loading || (items.length === 0 && loadingInBackground)"
          large
        />
        <p v-else-if="items.length === 0">
          Brak wyników, wprowadź lub zmień filtry
        </p>
        <div v-else class="search__results-items">
          <slot :items="items" :advanced="advanced" class="mb-4" />

          <base-more-button
            v-if="hasMore || loadingPage"
            @click="loadPage"
            :loading="loadingPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SearchBar from './components/SearchBar';
import { getAdvancedQuery } from './util/getAdvancedQuery';
import { getSplitQueries } from './util/getSplitQueries';

const CancelToken = axios.CancelToken;
const PER_PAGE = 10;

export default {
  components: {
    SearchBar,
  },
  props: {
    searchApi: String,
    mapItems: Function,
    advancedCollapse: Boolean,
    filters: Array,
    simpleCombo: Boolean,
    sublist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingPage: false,
      page: 1,
      count: 0,
      items: [],
      query: '',
      splitQueries: {},
      pendingRequests: [],
      invalidQuery: null,
    };
  },
  computed: {
    comboEndpoints() {
      if (this.simpleCombo) {
        return {
          combo_easy: 0,
        };
      }
      return {
        combo_easy: 0,
        combo_2d: 0,
        combo_w: 0,
        combo_m: 0,
        combo_hy: 0,
        combo_y: 0,
        combo_full: 0,
      };
    },
    emptySearch() {
      return !this.combo && !this.advanced;
    },
    advanced() {
      return this.$route.query.advanced;
    },
    combo() {
      return this.$route.query.combo;
    },
    desktop() {
      return this.$util.mq('desktop');
    },
    advancedSearchOpen() {
      if (this.$util.mq('desktop')) {
        return true;
      }
      return this.$store.state.advancedSearchOpen;
    },
    itemsVisible() {
      if (this.combo || this.$util.mq('desktop')) {
        return true;
      }
      return !this.advancedSearchOpen;
    },
    pageQuery() {
      return this.page === 1 ? '' : `page=${this.page}&`;
    },
    hasMore() {
      return this.page * PER_PAGE < this.count;
    },
    loadingInBackground() {
      return this.pendingRequests.length > 0;
    },
  },
  watch: {
    $route(to, from) {
      if (to.query.advanced) {
        window.scrollTo(0, 0);
        this.resetResults();
        return;
      }
      if (to.query.combo !== from.query.combo) {
        this.resetResults();
        this.loadPage();
      }
    },
    loading: {
      immediate: true,
      handler: function(v) {
        this.$emit('loading', v);
      },
    },
    items: {
      immediate: true,
      handler: function(v) {
        this.$emit('searchItems', v);
      },
    },
  },
  created() {
    if (!this.advanced && this.combo) {
      this.loadPage();
    }
  },
  destroyed() {
    this.cancelPending();
  },
  methods: {
    search() {
      if (this.advancedCollapse) {
        this.$emit('advanced-open');
      }
      this.$store.commit('setAdvancedSearchOpen', false);
      this.resetResults();
      const { query, splitKeys } = getAdvancedQuery(this.filters);
      this.query = query;
      this.splitQueries = getSplitQueries(splitKeys);
      this.loadPage();
      this.$emit('search');
      window.scrollTo(0, 0);
    },
    validateSearchQuery() {
      const restrictedWords = {
        lex_search: ['ustawa', 'projekt'],
        regulation_search: ['rozporządzenie'],
        european_union_act_search: ['dyrektywa', 'rozporządzenie'],
      };

      const searchApi = this.searchApi;
      const combo = this.combo.trim().toLowerCase();

      if (restrictedWords[searchApi]) {
        const isOnlyRestrictedWord = restrictedWords[searchApi].some(
          word => combo === word
        );

        if (isOnlyRestrictedWord) {
          this.invalidQuery = true;
          return false;
        }
      }
      this.invalidQuery = false;
      return true;
    },
    async loadCombo() {
      if (!this.validateSearchQuery()) {
        return;
      }
      const page = this.page;
      const pageQuery = this.pageQuery;
      this.startLoading();

      for (const key of Object.keys(this.comboEndpoints)) {
        if (page !== 1 && this.comboEndpoints[key] <= page * PER_PAGE) {
          continue;
        }
        const source = CancelToken.source();
        this.pendingRequests.push({
          id: key,
          cancel: source.cancel,
        });

        const { data } = await this.$api.get(
          `v1/${this.searchApi}/?${pageQuery}${key}=${this.combo}`,
          {
            cancelToken: source.token,
          }
        );
        this.pendingRequests = this.pendingRequests.filter(
          req => req.id !== key
        );
        this.comboEndpoints[key] = data.count;
        this.addItems(data);
        this.endLoading();
      }
    },
    async loadAdvanced() {
      const page = this.page;
      const query = this.query;
      const pageQuery = this.pageQuery;
      const splitQueriesKeys = Object.keys(this.splitQueries);
      this.startLoading();

      if (splitQueriesKeys.length === 0) {
        const { data } = await this.$api.get(
          `v1/${this.searchApi}/?${pageQuery}${query}`
        );
        this.addItems(data);
        this.endLoading();
      } else {
        for (const key of splitQueriesKeys) {
          if (page !== 1 && this.splitQueries[key] <= page * PER_PAGE) {
            continue;
          }
          const source = CancelToken.source();
          this.pendingRequests.push({
            id: key,
            cancel: source.cancel,
          });
          const { data } = await this.$api.get(
            `v1/${this.searchApi}/?${pageQuery}${query}&${key}`,
            {
              cancelToken: source.token,
            }
          );
          this.pendingRequests = this.pendingRequests.filter(
            req => req.id !== key
          );
          this.splitQueries[key] = data.count;
          this.addItems(data);
          this.endLoading();
        }
      }
    },
    loadPage() {
      if (this.advanced) {
        this.loadAdvanced();
      } else {
        this.loadCombo();
      }
      this.page++;
    },
    resetResults() {
      this.count = 0;
      this.page = 1;
      this.items = [];
      this.cancelPending();
    },
    addItems(data) {
      this.count = Math.max(data.count, this.count);
      const newItems = [];
      data.results.forEach(item => {
        const duplicate =
          this.items.find(i => i.id === item.pk) ||
          newItems.find(i => i.pk === item.pk);
        if (!duplicate) {
          newItems.push(item);
        }
      });
      this.items = this.items
        .concat(this.mapItems(newItems))
        .sort((a, b) => (a.date > b.date ? -1 : 1));
    },
    cancelPending() {
      this.pendingRequests.forEach(req => req.cancel());
      this.pendingRequests = [];
    },
    startLoading() {
      if (this.page === 1) {
        this.loading = true;
      } else {
        this.loadingPage = true;
      }
    },
    endLoading() {
      this.loading = false;
      this.loadingPage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Search';
</style>
