<template>
  <div class="nav-search" :class="{ 'nav-search--visible': active }">
    <form
      @submit.prevent="search(false)"
      class="nav-search__form mb-12 mb-desktop-4"
    >
      <input
        v-model="searchTerm"
        type="search"
        class="nav-search__input mb-4 mb-desktop-0"
        placeholder="wpisz szukaną frazę"
        autocomplete="false"
        ref="searchInput"
        required
      />

      <nav
        class="search-item-nav mb-4 mobile-tablet"
        :class="{ 'search-item-nav--more-open': moreOpen }"
      >
        <li class="search-item-nav__item">
          <router-link class="search-item-nav__link" to="leges"
            >ustawy</router-link
          >
        </li>
        <li class="search-item-nav__item">
          <router-link class="search-item-nav__link" to="regulations"
            >rozporządzenia</router-link
          >
        </li>
        <li
          class="search-item-nav__item tablet-desktop"
          v-for="act in otherActs"
          :key="act.name"
        >
          <router-link class="search-item-nav__link" :to="act.routeName">
            {{ act.name }}</router-link
          >
        </li>
        <li
          class="search-item-nav__item mobile"
          v-click-outside="() => (moreOpen = false)"
        >
          <button
            type="button"
            class="search-item-nav__link search-item-nav__more"
            :class="{ 'search-item-nav__more--open': moreOpen }"
            @click="moreOpen = !moreOpen"
          >
            inne akty
          </button>
          <ul
            class="search-item-nav__more-list"
            :class="{ 'search-item-nav__more-list--open': moreOpen }"
          >
            <li
              class="search-item-nav__more-item"
              v-for="act in otherActs"
              :key="act.name"
            >
              <router-link
                class="search-item-nav__link search-item-nav__more-link"
                :to="act.routeName"
              >
                {{ act.name }}</router-link
              >
            </li>
          </ul>
        </li>
      </nav>

      <button type="submit" class="nav-search__submit">
        <span class="visuallyhidden">szukaj</span>
        <base-icon-search />
      </button>
    </form>
    <div class="nav-search__advanced">
      <button @click="search(true)" class="nav-search__link">
        Wyszukiwanie zaawansowane
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    relation: String,
  },
  data() {
    return {
      searchTerm: this.$route.query.combo || '',
      searchActive: false,
      moreOpen: false,
      selectedRelation: '',
    };
  },
  computed: {
    otherActs() {
      return this.$store.getters['user/otherActs']
        .filter(act => !this.$util.OTHER_ACT_NOT_SEARCHABLE.includes(act))
        .map(act => ({
          name: act,
          routeName: this.$util.OTHER_ACT_MAP[act],
        }));
    },
  },
  watch: {
    active(isActive) {
      if (isActive) {
        this.$refs.searchInput.focus();
      }
    },
  },
  methods: {
    search(advanced = false) {
      const relation =
        this.relation || this.$route.meta.relation || 'legislation';

      this.$emit('search');
      this.$refs.searchInput.blur();
      this.$router.push({
        name: `search-${relation}`,
        query: advanced ? { advanced: true } : { combo: this.searchTerm },
      });
      if (advanced) {
        this.$store.commit('setAdvancedSearchOpen', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './SearchBar';
</style>
