export const getSplitQueries = list => {
  if (list.length === 0) {
    return {};
  }
  const out = list
    .map(({ keys, value }) => keys.map(key => `${key}=${value}`))
    .reduce((group1, group2) =>
      group1.flatMap(query1 => group2.map(query2 => `${query1}&${query2}`))
    )
    .map(query => [query, 0]);
  return Object.fromEntries(out);
};
